@import 'microtip';
.App {
   text-align: center;
}

* {
   box-sizing: border-box;
}

@font-face {
   font-family: 'Gilroy-bold';
   src: url('../fonts/Gilroy/Gilroy-Semibold.ttf');
}
@font-face {
   font-family: 'Gilroy-medium';
   src: url('../fonts/Gilroy/Gilroy-Medium.ttf');
}

@font-face {
   font-family: 'Gilroy';
   src: url('../fonts/Gilroy/Gilroy-Regular.ttf');
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-webkit-autofill:active {
   color: #1f6ed4 !important;
   background-color: transparent !important;
   background: transparent !important;
   transition: background-color 5000s ease-in-out 0s;
   -webkit-text-fill-color: #707070;
}
